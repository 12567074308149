import React, { useState } from "react";
import { navigate } from "gatsby";
import PropTypes from "prop-types";
import { Button, Col, Form, Row, Select } from "antd";
import { BusinessTypes, Position } from "../../data/common-data";
import chevron from "../../images/svg/chevron_left.svg";

const { Option } = Select;

const SsiForm = (props) => {
  const { btnData } = props;
  const [actionPath, setActionPath] = useState("");

  function handleChange(value) {
    switch (value) {
      case "restaurants":
        setActionPath("/restaurants");
        break;
      case "retail":
        setActionPath("/retail");
        break;
      case "auto":
        setActionPath("/automotive");
        break;
      case "health-and-beauty":
        setActionPath("/health-and-beauty");
        break;
      case "professional-services":
        setActionPath("/professional-services");
        break;
      case "other":
        setActionPath("/demo");
        break;
      default:
        setActionPath("/demo");
    }
  }

  const handleSubmit = () => {
    navigate(actionPath);
  };
  return (
    <Form layout="vertical" onFinish={handleSubmit}>
      <Row gutter={[32, 0]} align="middle">
        <Col lg={8} sm={24} xs={24}>
          <Form.Item
            style={{ overflow: "auto", width: "100% !important" }}
            name="business"
            rules={[
              {
                required: true,
                message: "Please select the business type",
              },
            ]}
            label="My business type:"
            aria-expanded
          >
            <Select
              onChange={handleChange}
              placeholder="Select your business type"
              style={{ width: "100% !important", color: "black !important" }}
              aria-expanded
            >
              {BusinessTypes.map(({ title, value }) => (
                <Option key={value} value={value}>
                  {title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={8} sm={24} xs={24}>
          <Form.Item
            style={{ overflow: "auto" }}
            name="interests"
            // rules={[
            //   {
            //     required: true,
            //     message: 'Please select what your looking for',
            //   },
            // ]}
            label="My role is:"
            aria-expanded
          >
            <Select
              placeholder="Select your role"
              style={{ width: "100%", color: "black !important" }}
              aria-expanded
            >
              {Position.map(({ title, value }) => (
                <Option key={value} value={value}>
                  {title}
                </Option>
              ))}
            </Select>
          </Form.Item>{" "}
        </Col>
        <Col lg={8} sm={24} xs={24}>
          <Form.Item className="ssi-form-submit">
            <Button
              type="primary"
              htmlType="submit"
              className="cta-primary"
              size="small"
            >
              {btnData.ctaTitle}
              <img
                src={chevron}
                alt="chevron icon"
                style={{ margin: "5px 5px 2px 10px" }}
              />
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

SsiForm.propTypes = {
  btnData: PropTypes.objectOf(PropTypes.any),
};
SsiForm.defaultProps = {
  btnData: "",
};

export default SsiForm;
