import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { motion } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import SsiForm from "./ssi-form";
import Title from "../Base/Title";
import { viewports } from "../../style-vars";
import useStartMotion from "../../hooks/use-start-motion";

const variants = {
  hidden: { opacity: 0, y: 100 },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const SSI = ({ sectionData, animations }) => {
  const { title, subtext, ctaInfo } = sectionData;
  const { ref, controls } = useStartMotion();

  return (
    <motion.section
      className={`ssi-v2 max-w-1440 lg:min-w-90-percent 2xl:min-w-1440
       bg-black-800 mt-20 lg:mt-36
        mx-4 lg:mx-8 px-6 xl:px-20 py-12
        rounded-large relative
      `}
      ref={ref}
      animate={controls}
      variants={variants}
      transition={{ duration: 0.8 }}
      initial={isMobileOnly || !animations ? "visible" : "hidden"}
    >
      <Row justify="center">
        <Col xs={24}>
          {title && (
            <>
              <Title level={2} className="text-center">
                {title}
              </Title>
              <p className="text-center text-black-200 text-p mb-12 px-4">
                {subtext}
              </p>
            </>
          )}
        </Col>
      </Row>
      <SsiForm btnData={ctaInfo} />

      <style jsx global>{`
        .ssi-v2 .cta-primary {
          width: 100%;
        }

        @media ${viewports.mdOrBigger} {
          .ssi-v2 .cta-primary {
            width: auto;
          }
        }

        @media ${viewports.lgOrBigger} {
          .ssi-v2 .cta-primary {
            margin-top: 24px;
            min-width: auto;
          }
        }
      `}</style>
    </motion.section>
  );
};

SSI.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
  animations: PropTypes.bool,
};
SSI.defaultProps = {
  sectionData: {},
  animations: true,
};

export default SSI;
